import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';
const PDF_TYPE = 'application/pdf';
const PDF_EXTENSION = '.pdf';
const IMG_TYPE = 'image/jpeg';
const img_extension = '.jpg'
@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor() { }
  public convertEpochToDate(timestamp) {
    var d = new Date();
    var localOffset = (1) * d.getTimezoneOffset() * 60000;
    return new Date((timestamp * 1000)+localOffset);
   
  }
  public convertDateToEpoch(date) {
    var currentDate = date;

    var currentTime = currentDate.getTime();

    var localOffset = (-1) * date.getTimezoneOffset() * 60000;

    var stamp = Math.round(new Date(currentTime + localOffset).getTime() / 1000);
    return stamp;
  }


  public toDateString(timestamp) {
    var dateString = this.convertEpochToDate(timestamp);    
    var mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthStr = mlist[dateString.getMonth()];
    if (dateString.getFullYear() < 1971) {
      return null;
    }
    return monthStr + " " + dateString.getDate() + ", " + dateString.getFullYear();
  }

  public DatetoDateString(date) {
    var dateString = date;
    var mlist = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var monthStr = mlist[dateString.getMonth()];
    if (dateString.getFullYear() < 1971) {
      return null;
    }
    return monthStr + " " + dateString.getDate() + ", " + dateString.getFullYear();
  }

  public DatetoMMDDYYYY(date) { 
      let year = date.getFullYear();
      let month = (1 + date.getMonth()).toString().padStart(2, '0');
      let day = date.getDate().toString().padStart(2, '0');

      return month + '-' + day + '-' + year;    
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }

  public saveAsPdfFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: PDF_TYPE });
    FileSaver.saveAs(data, fileName + PDF_EXTENSION);
  }
  public saveFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer]);
    FileSaver.saveAs(data, fileName);
  }

  public saveAsjpgFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: IMG_TYPE });
    FileSaver.saveAs(data, fileName + img_extension);
  }
  public chkSession() {
    var now = new Date();
    var started = new Date(sessionStorage.getItem("sessionTime"));
    var diff = (now.getTime() - started.getTime()) / 1000;
    diff /= 60;
    var elapsedmins = Math.abs(Math.round(diff))
    return elapsedmins;
  }
}

import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { LoginService } from "src/app/services/login/login.service";

@Component({
  selector: "app-verify-otp",
  templateUrl: "./verify-otp.component.html",
  styleUrls: ["./verify-otp.component.css"],
})
export class VerifyOtpComponent implements OnInit {
  public errMsg = null;
  selectMfaForm: FormGroup;
  userId: number | null;
  public userInfo: any;
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private loginService: LoginService
  ) {
    this.selectMfaForm = this.formBuilder.group({
      accessCode: ["", []],
    });
    this.userInfo = JSON.parse(sessionStorage.getItem("usrInfo"));
    this.userId = Number(this.userInfo.Id);
  }

  ngOnInit() {}

  OtpVerify() {
    if (this.selectMfaForm.valid) {
      const formData = {
        userId: this.userId,
        accessCode: this.selectMfaForm.value.accessCode,
      };
      this.loginService.OtpVerify(formData).subscribe(
        (data: any) => {
          window.name = data.UserName;
          data.tabname = window.name;
          data.IsAdmin = data.Role == "ADMIN" ? true : false;
          data.IsPowerUser = data.Role == "POWERUSER" ? true : false;
          data.IsUser = data.Role == "USER" ? true : false;
          sessionStorage.setItem("usrInfo", JSON.stringify(data));

          sessionStorage.setItem("sessionTime", new Date().toString());
          switch (data.AppID) {
            case 0:
              this._router.navigate(["admin"]);
              break;
            case 1:
              if (data.IsUser) {
                this._router.navigate(["dds"]);
              } else {
                this._router.navigate(["dds/dashboard"]);
              }
              break;
            case 2:
              if (data.IsUser) {
                this._router.navigate(["dcra"]);
              } else {
                this._router.navigate(["dcra/dashboard"]);
              }
              break;
          }
        },
        (error: any) => {
          this.errMsg = error.error.message;
        }
      );
    }
  }
}

import { VerifyOtpComponent } from "./pages/verify-otp/verify-otp.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AuthGuard } from "./services/authGuard/auth-guard.service";
import { LoginComponent } from "./pages/login/login.component";
import { AdminDashboardComponent } from "./pages/admin-dashboard/admin-dashboard.component";
import { SelectMfaComponent } from "./pages/select-mfa/select-mfa.component";

const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "select-mfa",
    component: SelectMfaComponent,
  },
  {
    path: "verify-otp",
    component: VerifyOtpComponent,
  },
  {
    path: "admin",
    component: AdminDashboardComponent,
    children: [
      {
        path: "dcra",
        loadChildren: () =>
          import("./DCRA/dcra.module").then((m) => m.DcraModule),
      },
      {
        path: "dds",
        loadChildren: () => import("./DDS/dds.module").then((m) => m.DdsModule),
      },
    ],
  },
  {
    path: "dds",
    loadChildren: () => import("./DDS/dds.module").then((m) => m.DdsModule),
  },
  {
    path: "dcra",
    loadChildren: () => import("./DCRA/dcra.module").then((m) => m.DcraModule),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

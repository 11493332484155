import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { url } from '../config/constants';
import { UtilitiesService } from '../services/utilities/utilities.service';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private _router: Router,private util: UtilitiesService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    var elapsedTime = this.util.chkSession();
    if (elapsedTime > 25) {
      this._router.navigate(['/login']);
    }
    else {
      sessionStorage.setItem("sessionTime", new Date().toString());
    }

    request = request.clone({
      url: url + request.url
     
    });

    if (JSON.parse(sessionStorage.getItem('usrInfo'))) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${JSON.parse(sessionStorage.getItem('usrInfo')).Token}`
        }
      });
    }
    return next.handle(request).pipe(
      retry(0),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token
          this._router.navigate(['/login']);
        } else {
          return throwError(error);
        }
      })
    );     
  }
    

}

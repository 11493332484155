import { Injectable, Inject } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate {
    public userInfo: userData;

  constructor(private _router: Router) {
    }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.userInfo = JSON.parse(sessionStorage.getItem('usrInfo'));
    if (!this.userInfo)
        {
            this._router.navigate(['/login']);
            return false;
    }
    //if (this.userInfo.tabname != window.name)
    //{
    //  this._router.navigate(['/login']);
    //  return false;
    //}
    if (this.userInfo.Token) {
            return true;
        }

        // navigate to login page
        this._router.navigate(['/login']);
        // you can save redirect url so after authing we can move them back to the page they requested
        return false;
    }

}
@Injectable()
export class AppGuard implements CanActivate {
  public userInfo: userData;

  constructor(private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.userInfo = JSON.parse(sessionStorage.getItem('usrInfo'));
    let AppId = next.data.AppId as number;
    if (this.userInfo.AppID == 0)
    {
      return true;
    }
    if (this.userInfo.AppID != AppId) {
      this._router.navigate(['/login']);
      return false;
    }
    else
    {
      return true;
    }

   
  }

}

class userData {
  public Id: number;
  public FirstName: string;
  public MiddleName: string;
  public LastName: string;
  public Email: string;
  public Username: string;
  public Token: string;
  public tabname: string;
  public Role: string;
  public PUMSTATUS: string;
  public AppID: number;
}

import { Component, OnInit, HostListener, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {

  opened = false;
  public IsInspector = false;
  public codiceuser = false;
  public page = 1;
  public userInfo: any;
  public IsDesktop = false;
  public hamburgerMargin = "hamburgerMarginAdd";
  constructor(public router: Router, public activatedRoute: ActivatedRoute, private cdr: ChangeDetectorRef, private http: HttpClient) { }

  ngOnInit() {
    if (window.innerWidth > 992) {
      this.IsDesktop = true;
    }
    this.userInfo = JSON.parse(sessionStorage.getItem('usrInfo'));
    window.addEventListener('storage', function (event) {
      if (event.key == 'logout-PP') {
        var getUrl = window.location;
        var baseUrl = getUrl.protocol + "//" + getUrl.host + "/" + (getUrl.pathname.split('/')[1] == "PaymentPlanet" ? "PaymentPlanet" : "");
        window.location.href = baseUrl + "/" + "login";
      }
    });
    
    //document.getElementById('title').style.width = (document.getElementById('titlecontainer').offsetWidth - 300).toString() + "px"; 
  }
  ngAfterViewInit() {
    this.hamburberMenuClick();
  }
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    if (window.innerWidth > 992) {
      this.IsDesktop = true;
    }
    else {
      this.IsDesktop = false;
    }
    if (this.opened == true) {
      this.hamburgerMargin = "hamburgerMarginAdd";
      document.getElementById('title').style.width = (document.getElementById('titlecontainer').offsetWidth).toString() + "px";
    }
    else {
      this.hamburgerMargin = "hamburgerMarginRemove";
      document.getElementById('title').style.width = (document.getElementById('titlecontainer').offsetWidth).toString() + "px";
    }
  }
  chk() {
    this.router.navigate([
      // NOTE: No relative-path navigation is required because we are accessing
      // the parent's "activatedRoute" instance. As such, this will be executed
      // as if we were doing this in the parent view component.
      {
        outlets: {
          adminrouter: ['//dcra//paymentInfo2']
        }
      }
    ],
      {
        relativeTo: this.activatedRoute.parent // <--- PARENT activated route.
      }
    );
  }

  hamburberMenuClick() {
    this.opened = !this.opened;
    if (this.opened) {
      this.hamburgerMargin = "hamburgerMarginAdd";
      document.getElementById('title').style.width = (document.getElementById('titlecontainer').offsetWidth - 250).toString() + "px";
    }
    else {
      this.hamburgerMargin = "hamburgerMarginRemove";
      document.getElementById('title').style.width = (document.getElementById('titlecontainer').offsetWidth + 250).toString() + "px";
    }
    this.cdr.detectChanges();
  }
  logout() {
    localStorage.setItem('logout-PP', 'logout' + Math.random());   
    this.http.get('User/logout/' + this.userInfo.Id + '/' + this.userInfo.AppID).subscribe((data: any) => {     
    });  
    this.router.navigate(['/login']);
  }
}

//Modules
import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppRoutingModule } from "./app-routing.module";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatCardModule } from "@angular/material/card";
import { MatDialogModule } from "@angular/material/dialog";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { MatSelectModule } from "@angular/material/select";
import { MatSnackBarModule } from "@angular/material/snack-bar";

import { MatToolbarModule } from "@angular/material/toolbar";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatExpansionModule } from "@angular/material/expansion";
//Constants
import { HTTP_INTERCEPTORS } from "@angular/common/http";
//Interceptors
import { TokenInterceptor } from "./interceptors/interceptors";

//Services
import { AuthGuard } from "./services/authGuard/auth-guard.service";

//Components
import { AppComponent } from "./app.component";
import {
  LoginComponent,
  AppSelectorDialog,
  AccLockedDialog,
} from "./pages/login/login.component";
import { AdminDashboardComponent } from "./pages/admin-dashboard/admin-dashboard.component";
import { FooterComponent } from "./DDS/components/footer/footer.component";
import { SelectMfaComponent } from "./pages/select-mfa/select-mfa.component";
import { VerifyOtpComponent } from "./pages/verify-otp/verify-otp.component";
import { MatRadioModule } from "@angular/material";

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    AppSelectorDialog,
    AccLockedDialog,
    AdminDashboardComponent,
    SelectMfaComponent,
    VerifyOtpComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    BrowserAnimationsModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatSelectModule,
    MatSnackBarModule,

    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatExpansionModule,
    MatRadioModule,
  ],
  entryComponents: [AppSelectorDialog, AccLockedDialog],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AuthGuard,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

import { Component, OnInit, Inject, TemplateRef } from "@angular/core";
import { Router } from "@angular/router";
import { LoginService } from "../../services/login/login.service";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { HostListener } from "@angular/core";
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public errMsg = null;
  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.charCode == 13) {
      this.login();
    }
  }
  constructor(
    private _router: Router,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    sessionStorage.clear();
    this.loginForm = this.formBuilder.group({
      Email: ["", []],
      Password: ["", []],
    });
  }
  login() {
    if (this.loginForm.valid) {
      this.loginService.Authenticate(this.loginForm.value).subscribe(
        (data: any) => {
          window.name = data.UserName;
          data.tabname = window.name;
          data.IsAdmin = data.Role == "ADMIN" ? true : false;
          data.IsPowerUser = data.Role == "POWERUSER" ? true : false;
          data.IsUser = data.Role == "USER" ? true : false;
          sessionStorage.setItem("usrInfo", JSON.stringify(data));

          sessionStorage.setItem("sessionTime", new Date().toString());
          if (data.MfaActive) {
            this._router.navigate(["verify-otp"]);
          } else {
            switch (data.AppID) {
              case 0:
                this._router.navigate(["admin"]);
                break;
              case 1:
                if (data.IsUser) {
                  this._router.navigate(["dds"]);
                } else {
                  this._router.navigate(["dds/dashboard"]);
                }
                break;
              case 2:
                if (data.IsUser) {
                  this._router.navigate(["dcra"]);
                } else {
                  this._router.navigate(["dcra/dashboard"]);
                }
                break;
            }
          }
        },
        (error: any) => {
          this.errMsg = error.error.message;
          if (error.error.isLocked) {
            this.openaccLockedDialog("Locked");
          }
          if (error.error.isInActive) {
            this.openaccLockedDialog("Inactive");
          }
        }
      );
    }
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(AppSelectorDialog, {
      width: "250px",
      data: [
        { name: "DDS", value: 1 },
        { name: "DCRA", value: 2 },
      ],
    });

    dialogRef.afterClosed().subscribe((result) => {
      switch (result) {
        case 1:
          this._router.navigate(["dds"]);
          break;
        case 2:
          this._router.navigate(["dcra/dashboard"]);
          break;
      }
    });
  }
  openDialogWithTemplateRef(templateRef: TemplateRef<any>) {
    const resetDialog = this.dialog.open(templateRef);
  }
  openaccLockedDialog(Type): void {
    const dialogRef = this.dialog.open(AccLockedDialog, {
      width: "250px",
      data: Type,
    });
  }
  forgotPassword() {
    let email = this.loginForm.get("Email");

    if (email.value) {
      this.loginService.ResetPassword(this.loginForm.value).subscribe(
        (data: any) => {
          let snackBarRef = this.snackBar.open(
            "Please login with the new password mailed to your email id",
            null,
            {
              verticalPosition: "top",
              duration: 4000,
            }
          );
        },
        (error: any) => {
          this.errMsg = error.error.message;
          if (error.error.isLocked) {
            this.openaccLockedDialog("Locked");
          }
          if (error.error.isInActive) {
            this.openaccLockedDialog("Inactive");
          }
        }
      );
    } else {
      this.errMsg = "Enter email id";
    }
  }
}
@Component({
  selector: "app-selector-dialog",
  templateUrl: "appselectortemplate.html",
})
export class AppSelectorDialog {
  public appOptions: any;
  public selectedApp: any;
  constructor(
    public dialogRef: MatDialogRef<AppSelectorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.appOptions = data;
    this.selectedApp = data[0].value;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

@Component({
  selector: "app-selector-dialog",
  templateUrl: "AccountLockedtemplate.html",
})
export class AccLockedDialog {
  public Type: any;

  constructor(
    public dialogRef: MatDialogRef<AccLockedDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.Type = data;
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
}

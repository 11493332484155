import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
@Injectable({
  providedIn: "root",
})
export class LoginService {
  constructor(private http: HttpClient) {}

  Authenticate(loginData) {
    return this.http.post("User/authenticate", loginData);
  }

  ResetPassword(loginData) {
    return this.http.post("User/resetPassword", loginData);
  }
  OtpVerify(otpData) {
    return this.http.post("User/verifyAccessCode", otpData);
  }
}
